// Add DOM4 support, https://github.com/WebReflection/dom4
import 'dom4';

// Add Babel polyfill for ES2015, https://babeljs.io/docs/usage/polyfill/
import 'babel-polyfill';

// Import components from Audi UI library
import {Checkbox, Radio, Select, Textfield, Nav} from '@audi/audi-ui';

// Initialize Audi UI components
Checkbox.upgradeElements();
Radio.upgradeElements();
Select.upgradeElements();
Textfield.upgradeElements();
Nav.upgradeElements();

export function upgradeCheckboxElements() {
    Checkbox.upgradeElements();
}
export function upgradeRadioElements() {
    Radio.upgradeElements();
}
export function upgradeSelectElements() {
    Select.upgradeElements();
}
export function upgradeTextfieldElements() {
    Textfield.upgradeElements();
}